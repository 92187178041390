import { animate, style, trigger, transition, } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';
import { MatDialog } from '@angular/material';
import { AssignCouponPopupComponent } from '../assign-coupon-popup/assign-coupon-popup.component';


@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SubscriptionListComponent implements OnInit {

  couponList: any
  couponAllData: any;
  subscriptionList: any
  loading = true;
  coupon_loading = true;
  selectCouponId = ""
  selectedCouponExpiryData: any;
  // @ViewChild(DataTableDirective,)
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  declinedUsercardFlag = false;
  assignedUsercardFlag = false;
  declinedUser = [];
  assignedUser = [];

  price = 99.99

  constructor(private toastr: ToastrService, private service: MyServicesService, public dialog: MatDialog) {
    this.getPrice()
    this.getCouponData()
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };


    // setTimeout(function () {
    //   $(function () {
    //     $('#user-table').DataTable().destroy()
    //     $('#user-table').DataTable(
    //       {
    //         retrieve: true,
    //         destroy: true,
    //         //   lengthMenu: [
    //         //     [5,10, 25, 50, -1],
    //         //     [5,10, 25, 50, 'All'],
    //         // ],
    //       }
    //     );
    //   });
    // }, 1500);

  }

  ngOnInit() {

  }


  getPrice() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/v1/users/get-plans', {}).subscribe(
      data => {
        //console.log(data)
        var res = JSON.stringify(data)
        var p = JSON.parse(res);
        this.price = p.data.data[0].price;
      },
      err => {

      });
  }

  getCouponData() {
    this.couponList = []
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-coupons', {}).subscribe(
      data => {
        //console.log(data)
        this.couponAllData = data
        for (var i = 0; i < this.couponAllData.data.data.length; i++) {
          var todayDate = new Date();
          // && (new Date().getTime() > new Date(c.expiry_date).getTime())

          var validT0 = new Date(this.couponAllData.data.data[i].valid_to)
          //console.log(todayDate.getTime() < validT0.getTime())
          if (this.couponAllData.data.data[i].status == 1 && (todayDate.getTime() < validT0.getTime())) {
            this.couponList.push(this.couponAllData.data.data[i])
          }
        }

        this.coupon_loading = false
        this.SubscriptionData();
      },
      err => {
        ////console.log(err)
      });
  }

  // (change)="changeEventForCoupon()"
  // changeEventForCoupon() {
  //   this.SubscriptionDataForChangeEvent();
  //   this.assignedUsercardFlag = false;
  //   this.assignedUser = [];
  //   this.declinedUsercardFlag = false;
  //   this.declinedUser = []
  //   this.loading = true;
  // }

  SubscriptionDataForChangeEvent() {

    this.selectedData = []
    for (var j = 0; j < this.couponList.length; j++) {
      if (this.selectCouponId == this.couponList[j].id) {
        this.selectedCouponExpiryData = this.couponList[j].valid_to
      }
    }

    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/subscribers-list', { coupon_id: this.selectCouponId }).subscribe(
      data => {

        this.subscriptionList = data

        this.loading = false
        setTimeout(function () {
          $('#user-table').DataTable().destroy();
          $(function () {

            $('#user-table').DataTable(
              {
                retrieve: true,
                destroy: true,
              }
            );

          });
        }, 500);

        // this.dtTrigger.next();
      },
      err => {
        ////console.log(err)
      });
  }

  SubscriptionData() {

    this.selectedData = []
    for (var j = 0; j < this.couponList.length; j++) {
      if (this.selectCouponId == this.couponList[j].id) {
        this.selectedCouponExpiryData = this.couponList[j].valid_to
      }
    }

    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/subscribers-list', { coupon_id: this.selectCouponId }).subscribe(
      data => {

        this.subscriptionList = data

        this.loading = false

        $('#user-table').DataTable().destroy();
        $(function () {

          $('#user-table').DataTable(
            {
              retrieve: true,
              destroy: true,
            }
          );

        });

        // this.dtTrigger.next();
      },
      err => {
        ////console.log(err)
      });
  }


  selectedData = []
  onChange(event, data) {
    //console.log(event.target.checked)
    if (this.selectCouponId == undefined || this.selectCouponId == "") {
      this.toastr.warning("Please select any coupon from the list!");
      event.target.checked = false;
      return
    }


    if (data.subscription_status != null) {
      var discount_amount;
      for (var j = 0; j < this.couponList.length; j++) {
        if (this.selectCouponId == this.couponList[j].id) {
          discount_amount = this.couponList[j].discount

        }
      }
      console.log(discount_amount)
      if (discount_amount == 100) {
        this.toastr.warning("This 100% discount is only applicable to the new user.");
        event.target.checked = false;
        return
      }
    }



    var checked = event.target.checked
    if (checked) {
      this.selectedData.push(data);
    }
    else {
      const index = this.selectedData.findIndex(x => x.id === data.id);
      this.selectedData.splice(index, 1) // removeAt(index);

    }

  }

  checkSelectedData() {
    //console.log(this.selectedData)
    var idArray = []; var discount_amount; var toPaid; var afterDiscount;
    for (var i = 0; i < this.selectedData.length; i++) {
      idArray.push(this.selectedData[i].user_id)
    }

    if (this.couponList.length == 0) {
      this.toastr.warning("Coupons are Inactive/expired/unavailable.")
      return;
    }
    for (var j = 0; j < this.couponList.length; j++) {
      if (this.selectCouponId == this.couponList[j].id) {
        discount_amount = this.couponList[j].discount
        afterDiscount = (this.price * (((this.couponList[j].discount)) / 100));
        toPaid = this.price - afterDiscount;
      }
    }
    var data = {
      "id": idArray,
      "coupon_id": this.selectCouponId,
      "discount_amount": Math.round(toPaid * 100) / 100
    }

    if (data.coupon_id == undefined || data.coupon_id == "") {
      this.toastr.warning("Coupon is required!")
      return;
    }

    if (idArray.length == 0) {
      this.toastr.warning("Please Select User")
      return;
    }
    //console.log(data);
    this.loading = true;
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/assign-coupon', data).subscribe(
      data => {


        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.message);
          // if (resultCode.data.declined_user.length > 0) {
          //   this.declinedUsercardFlag = true;
          //   this.declinedUser = resultCode.data.declined_user
          // }
          // if (resultCode.data.assigned_user.length > 0) {
          //   this.assignedUsercardFlag = true;
          //   this.assignedUser = resultCode.data.assigned_user
          // }
          this.SubscriptionData();
          // this.goBack();
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 404) {
          this.toastr.error(resultCode.data.message)
          // if (resultCode.data.declined_user.length > 0) {
          //   this.declinedUser = resultCode.data.declined_user
          //   this.declinedUsercardFlag = true;
          //   console.log(this.declinedUser)
          // }
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        ////console.log(err)
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 200) {
          this.toastr.success(p.error.data.message);
          //this.SubscriptionData();

          // this.goBack();
        }
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          // if (p.error.data.declined_user.length > 0) {
          //   this.declinedUsercardFlag = true;
          //   this.declinedUser = p.error.data.declined_user

          //   console.log(this.declinedUser[0])
          // }

          this.SubscriptionData();

          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }
        this.toastr.error("Something went wrong!")
      });
    this.loading = false
  }

  callFun(data) {
    //console.log(data.expiry_date)
    //console.log(new Date().getTime() > new Date(data.expiry_date).getTime())
    // if ((data.subscription_status == 1 && (new Date().getTime() < new Date(data.expiry_date).getTime())) || data.subscription_status == null && data.assigned_coupon_status == null) {
    //   return false
    // }


    // if (this.selectedCouponExpiryData == undefined) {
    //   return true
    // }
    // else {
    //   if (!(new Date().getTime() > new Date(data.expiry_date).getTime())) {
    //     return true
    //   }
    //   else if (data.assigned_coupon_status == 2 || data.assigned_coupon_status == 3) {
    //     return true
    //   }
    //   else {
    //     return false
    //   }
    // }

    // if (data.assigned_coupon_status == null || data.subscription_status == null) {
    //   return false
    // }
    // else if (data.assigned_coupon_status == 2) {
    //   return true
    // }
    // else if (data.assigned_coupon_status == 3 && data.payment_status == 1 && (new Date().getTime() < new Date(data.expiry_date).getTime())) {
    //   return false
    // }

    // else {
    //   return true
    // }

    if (data.assigned_coupon_status == 2 || data.payment_status == 0) {
      return true
    }
    else if (data.subscription_status == 4) {
      return false
    }
    else if (data.payment_status == null || data.subscription_status == 4 || data.assigned_coupon_status == 3) {
      return false
      // if (data.discount == 100) {
      //   return true
      // }
      // else {
      //   return false
      // }
    }
    else if (data.assigned_coupon_status == 3 && data.payment_status == 1 && (new Date().getTime() > new Date(data.expiry_date).getTime())) {
      return true
    }
    else if (data.assigned_coupon_status == null) {
      if (data.expiry_date == null) {
        return false
      }
      else if ((new Date().getTime() > new Date(data.expiry_date).getTime())) {
        return true
      }

    }


    else {
      return false
    }
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next('');
    });
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dialog.closeAll()
  }

  assignedCloseIcon() {
    this.assignedUsercardFlag = false
  }

  declinedCloseIcon() {
    this.declinedUsercardFlag = false
  }

  callRemoveCouponPopup(data) {
    this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to remove the coupon"
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        console.log(("Refund Component Yesss"))
        this.removeCoupon(data)
      }
      else {

      }
    });
  }

  removeCoupon(data: any) {
    var request = {
      "id": [data.user_id],
      "coupon_id": data.coupon_id,
      "assigned_coupon_id": data.assigned_coupon_id,
      "payment_id": data.payment_id,
      "subscription_id": data.subscription_id
    }
    console.log(request)
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/remove-coupon', request).subscribe(
      data => {
        //console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {

          this.toastr.success(resultCode.data.message);
          this.SubscriptionData();
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p.error.data)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)

          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }

        this.toastr.error("Something went wrong!")
      });
  }

  

}
