import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-assign-coupon-popup',
  templateUrl: './assign-coupon-popup.component.html',
  styleUrls: ['./assign-coupon-popup.component.scss']
})
export class AssignCouponPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data : any,
  public dialogRef: MatDialogRef<AssignCouponPopupComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close(false);
  }

}
