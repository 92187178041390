import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';
import { AssignCouponPopupComponent } from '../assign-coupon-popup/assign-coupon-popup.component';

@Component({
  selector: 'app-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.scss']
})
export class ReviewPageComponent implements OnInit {

  loading = true
  reviewsList
  constructor(private service: MyServicesService, private toastr: ToastrService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getReviewsData()
  }

  page = 1;
  search = ''
  limit = 10;
  pageSize = 10;
  total = 10
  pageSizes = [10, 25, 50, 100]


  getReviewsData() {
    var data = {
      "skip": (this.page - 1) * this.limit,
      "limit": this.limit,
      "search": this.search
    }
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-all-reviews', data).subscribe(
      data => {
        console.log(data)
        this.reviewsList = data
        this.loading = false
        this.total = this.reviewsList.data.review_count
      },
      err => {
        //console.log(err)
      });
  }


  toggleReviewStatus(reviewData) {
    var data = {
      "id": reviewData.id,
      "status": !reviewData.status
    }
    console.log(data)
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/toggle-review-status', data).subscribe(
      data => {
        //console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.getReviewsData()
          this.toastr.success(resultCode.data.message);

        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }

        this.toastr.error("Something went wrong!")
      });
  }


  openDialog(reviewData): void {
    this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to change the status"
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        this.toggleReviewStatus(reviewData)
      }
      else {

      }
    });
  }

  handlePageChange(event: any) {
    this.page = event;
    this.getReviewsData()
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.limit = parseInt(event.target.value);
    //console.log(this.limit)
    this.page = 1;
    this.getReviewsData()
  }

  searchTitle(): void {
    this.page = 1;
    this.getReviewsData()
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

}
