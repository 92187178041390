import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class UserProfileComponent implements OnInit {

  editProfile = true;
  editProfileIcon = 'icofont-edit';

  editAbout = true;
  editAboutIcon = 'icofont-edit';

  public basicContent: string;


  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  profitChartOption: any;

  id
  userList: any
  constructor(private service: MyServicesService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.id = params['id'];

    });
    this.getUserData();
  }

  ngOnInit() {
  }

  toggleEditProfile() {
    this.editProfileIcon = (this.editProfileIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editProfile = !this.editProfile;
  }

  toggleEditAbout() {
    this.editAboutIcon = (this.editAboutIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editAbout = !this.editAbout;
  }

  loading = true;
  getUserData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-user', { id: this.id }).subscribe(
      data => {
        console.log(data)
        this.userList = data
        this.loading = false
        if (this.userList.data.user_data.roles_id == 4) {
          if (this.userList.data.user_data.doc_url != null || this.userList.data.user_data.doc_url != "") {
            this.imageObject.push({ image: this.userList.data.user_data.doc_url })
            this.imageObject.push({ image: this.userList.data.user_data.doc_url_back })

          }

        }
      },
      err => {
        //console.log(err)
      });
  }

  imageObject: Array<object> = [
  ];
  showFlag: boolean = false;
  selectedImageIndex: number = -1;


  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;

  }
}
