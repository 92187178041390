import { Component, OnInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';


@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {

  userList :any
  loading =  true ;
  // @ViewChild(DataTableDirective,)
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private toastr: ToastrService, private service: MyServicesService,) {
       this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };
   }

  ngOnInit() {
    this.getUserData();
  }


  getUserData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-admin-user', {}).subscribe(
      data => {
        console.log(data)
        this.userList = data
        this.loading = false
        $(function () {
          $('#user-table').DataTable().destroy()
          $('#user-table').DataTable(
            {
            }
          );
        });
      },
      err => {
        //console.log(err)
      });
  }


}
