import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';
import { AssignCouponPopupComponent } from '../assign-coupon-popup/assign-coupon-popup.component';
import { UpdateSequenceVideoPopupComponent } from '../update-sequence-video-popup/update-sequence-video-popup.component';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {


  videoList: any
  loading = true;
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private service: MyServicesService, private toastr: ToastrService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getVideoData();
  }

  getVideoData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/list-urls', {}).subscribe(
      data => {
        console.log(data)
        this.videoList = data
        this.loading = false
        $(function () {
          $('#user-table').DataTable().destroy()
          $('#user-table').DataTable(
            {
            }
          );
        });
      },
      err => {
        console.log(err)
        if(err.error.data.code == 404){
          this.videoList ={
            "data": {
                "data": []
              }
            }
        }
        this.loading = false

      });
  }


  getVideoDatasss() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/list-urls', {}).subscribe(
      data => {
        console.log(data)
        this.videoList = data
        this.loading = false

      },
      err => {
        //console.log(err)
        if(err.error.data.code == 404){
          this.videoList ={
            "data": {
                "data": []
              }
            }
        }
        this.loading = false
      });
  }

  DeleteURL(c) {
    var data = {
      "id": c.id,
      "status": 9
    }
    this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to Delete the Video "
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        this.service.postMethodGetData(this.service.adminAPI + 'api/admin/toggle-urls-status', data).subscribe(
          data => {

            var result = JSON.stringify(data)
            var resultCode = JSON.parse(result)
            if (resultCode.data.code == 200) {
              this.toastr.success(resultCode.data.message);
              this.getVideoDatasss()
            }
            if (resultCode.data.code == 403) {
              this.toastr.error(resultCode.data.message)
              return;
            }
            if (resultCode.data.code == 401) {
              this.toastr.error(resultCode.data.message)
              return
            }
            if (resultCode.data.code == 400) {
              this.toastr.error(resultCode.data.message)
              return;
            }

          },
          err => {
            var data = JSON.stringify(err)
            var p = JSON.parse(data);
            console.log(p)
            if (p.error.data.code == 403) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 401) {
              this.toastr.error(p.error.data.message)
              return
            }
            if (p.error.data.code == 400) {
              this.toastr.error(p.error.data.message)
              return;
            }

            if (p.error.data.code == 404) {
              this.toastr.error(p.error.data.message)
              return;
            }

            if (p.error.data.code == 422) {
              this.toastr.error(p.error.errors.message)
              return;
            }
            if (p.error.data.code == 404) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 500) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 501) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 502) {
              this.toastr.error(p.error.data.message)
              return;
            }

            this.toastr.error("Something went wrong!")
          });
      }
      else {

      }
    });
  }



  ActiveDeactive(res: any) {
    var data = {
      "id": res.id,
      "status": res.status == 0 ? 1 : 0
    }
    console.log(data)
    this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to Change the Status "
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        this.service.postMethodGetData(this.service.adminAPI + 'api/admin/toggle-urls-status', data).subscribe(
          data => {

            var result = JSON.stringify(data)
            var resultCode = JSON.parse(result)
            if (resultCode.data.code == 200) {
              this.toastr.success(resultCode.data.message);
              this.getVideoDatasss()
            }
            if (resultCode.data.code == 403) {
              this.toastr.error(resultCode.data.message)
              return;
            }
            if (resultCode.data.code == 401) {
              this.toastr.error(resultCode.data.message)
              return
            }
            if (resultCode.data.code == 400) {
              this.toastr.error(resultCode.data.message)
              return;
            }

          },
          err => {
            var data = JSON.stringify(err)
            var p = JSON.parse(data);
            console.log(p)
            if (p.error.data.code == 403) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 401) {
              this.toastr.error(p.error.data.message)
              return
            }
            if (p.error.data.code == 400) {
              this.toastr.error(p.error.data.message)
              return;
            }

            if (p.error.data.code == 404) {
              this.toastr.error(p.error.data.message)
              return;
            }

            if (p.error.data.code == 422) {
              this.toastr.error(p.error.errors.message)
              return;
            }
            if (p.error.data.code == 404) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 500) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 501) {
              this.toastr.error(p.error.data.message)
              return;
            }
            if (p.error.data.code == 502) {
              this.toastr.error(p.error.data.message)
              return;
            }

            this.toastr.error("Something went wrong!")
          });
      }
      else {
        this.getVideoDatasss()
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.videoList.data.data, event.previousIndex, event.currentIndex);
    this.dialog.open(UpdateSequenceVideoPopupComponent, {
      width: '450px',
      data: {
        message: "The order Sequence has been changed. Would you like to save it?. Please click the update sequence button."
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        console.log(("Refund Component Yesss"))

      }
      else {
        console.log(("Refund Component elseeeees"))

      }
    });
  }




  updateSequenceOrder() {
    var d = []
    for (var i = 0; i < this.videoList.data.data.length; i++) {
      d.push(this.videoList.data.data[i].id)
    }
    //console.log({order :d})
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/update-sequence-order', { order_sequence: d }).subscribe(
      res => {
        var result = JSON.stringify(res)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.message);
          this.getVideoDatasss()
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        this.getVideoDatasss();
      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }

        this.toastr.error("Something went wrong!")
        this.getVideoDatasss()
      });
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

}
