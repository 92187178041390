import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../../CCNServices/Service/my-services.service';


@Component({
  selector: 'app-view-coupon',
  templateUrl: './view-coupon.component.html',
  styleUrls: ['./view-coupon.component.scss']
})
export class ViewCouponComponent implements OnInit {

  id
  couponData={
    'id' :'',
    "name": "",
    "description":"",
    "coupon_code": "",
    "discount": "",
    "discount_type": 1,
    "valid_from": "",
    "valid_to": "",
    "status": 0
  }

  constructor(private toastr: ToastrService, private service: MyServicesService, private route: ActivatedRoute) { 
    this.route.params.subscribe(params => {
      this.id = params['id'];
     
    });
    this.getCouponData()
  }

  ngOnInit() {
  }

  getCouponData(){
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/view-coupon', {id : this.id}).subscribe(
      data => {
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result);
       this.couponData.name =resultCode.data.couponData.coupon_name
       this.couponData.coupon_code =resultCode.data.couponData.coupon_code
       this.couponData.description =resultCode.data.couponData.coupon_description
       this.couponData.discount =resultCode.data.couponData.coupon_discount
       this.couponData.valid_from =resultCode.data.couponData.coupon_valid_from
       this.couponData.valid_to =resultCode.data.couponData.coupon_valid_to
       this.couponData.id = this.id
       this.couponData.status = resultCode.data.couponData.coupon_status
      },
      err => {
        //console.log(err)
      });
  }


}
