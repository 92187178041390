import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';


@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.scss']
})
export class CreateAdminComponent implements OnInit {
  group: FormGroup;
  saveLoading = false;
  constructor(private toastr: ToastrService, private service: MyServicesService) { }

  ngOnInit() {
  }

  createAdminData={
    "user_name": "",
    "email": "",
    "password": "",
    "mobile": "",
    "areas_id": "1",
    "roles_id": "1"
    
}


  addCouponData(){

    if (this.createAdminData.user_name== '') {
      this.toastr.warning("Username is required!")
      return;
    }
    if (this.createAdminData.email == '') {
      this.toastr.warning("Email is required!")
      return;
    }
    if (this.createAdminData.password == '') {
      this.toastr.warning("Password is required!")
      return;
    }
    if (this.createAdminData.mobile == '') {
      this.toastr.warning("Mobile is required!")
      return;
    }
  
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/sign-up', this.createAdminData).subscribe(
      data => {
        //console.log(data);
        this.saveLoading = false
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.data);
          this.saveLoading = false
          // this.goBack();
        }
      },
      err => {
        //console.log(err)
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        this.saveLoading = false
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.data)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.data)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.data)
          return;
        }
        if (p.error.data.code == 422) {
          this.toastr.error(p.error.data.data)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.data)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.data)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.data)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.data)
          return;
        }

        this.toastr.error("Something went wrong!")
      });
  }


  ClickCancel(){
    this.createAdminData={
      "user_name": "",
      "email": "",
      "password": "",
      "mobile": "",
      "areas_id": "1",
      "roles_id": "1"
      
  }
  }
}
