import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService implements HttpInterceptor{

  constructor(private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = window.localStorage.getItem("Token");
    // you probably want to store it in localStorage or something
    // const forgetPasswordpage =window.localStorage.getItem("ForgotPassword")
   
    //console.log("REQUEST " + req.url);
    if(token == null){
      this.router.navigate([`../login`]);
     }
    //  if(forgetPasswordpage == 'yes'){
    //   this.router.navigate([`../forgot-password`]);
       
    // }
    if (!token) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set('Authorization', `${token}`),
    });

     
    return next.handle(req1);
  }
}
