import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyServicesService {

  // adminAPI = "https://credit.thepermatech.com/";
  adminAPI = "https://app.thecreditconscious.com/";
  constructor(private http: HttpClient) { }

  postMethodGetData(api: any, data: any,) {
    return this.http.post(api, data)
  }

  postMethod(api: any, data: any,) {
    return this.http.post(api, data)
  }

  getMethod(api: any) {
    return this.http.get(api)
  }

  getMethodGetData(api: any, data: any,) {
    return this.http.get(api, data)
  }

  updateMethodById(api: any, id: any, data: any) {
    return this.http.put(api + "/" + id, data)
  }

  deleteMethodById(api: any, id: any) {
    return this.http.delete(api + "/" + id, { responseType: 'text' })
  }
}
