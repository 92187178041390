import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent implements OnInit {


  group: FormGroup;
  saveLoading = false;
  todayAValidFrom = new Date().toLocaleDateString('en-CA');
  constructor(private router: Router, private toastr: ToastrService, private service: MyServicesService) { }

  ngOnInit() {
    console.log(this.todayAValidFrom)

  }

  couponData = {
    "name": "",
    "description": "",
    "coupon_code": "",
    "discount": "",
    "discount_type": 1,
    "valid_from": "",
    "valid_to": ""
  }


  addCouponData() {

    if (this.couponData.name == '') {
      this.toastr.warning("Name is required!")
      return;
    }
    if (this.couponData.coupon_code == '') {
      this.toastr.warning("Coupon code is required!")
      return;
    }
    if (this.couponData.description == '') {
      this.toastr.warning("Description is required!")
      return;
    }
    if (this.couponData.discount == '') {
      this.toastr.warning("Discount is required!")
      return;
    }
    if (this.couponData.valid_from == '') {
      this.toastr.warning("Valid From is required!")
      return;
    }
    if (this.couponData.valid_to == '') {
      this.toastr.warning("Valid to is required!")
      return;
    }
    console.log((parseInt(this.couponData.discount, 10)) > 100)
    if ((parseInt(this.couponData.discount, 10)) > 100) {
      this.toastr.warning("Discount must be a Percentage")
      return;
    }
    console.log(new Date(this.couponData.valid_from).getTime() > new Date(this.couponData.valid_to).getTime())
    if (new Date(this.couponData.valid_from).getTime() > new Date(this.couponData.valid_to).getTime()) {
      this.toastr.warning("The valid from must be a date before or equal to valid to.")
      return;
    }
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/add-coupons', this.couponData).subscribe(
      data => {
        //console.log(data);
        this.saveLoading = false
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.data);
          this.saveLoading = false
          // this.goBack();
          this.router.navigate([`../couponList`]);
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.data)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.data)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.data)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }
        this.saveLoading = false
        this.toastr.error("Something went wrong!")
      });
  }


  ClickCancel() {
    this.couponData = {
      "name": "",
      "description": "",
      "coupon_code": "",
      "discount": "",
      "discount_type": 1,
      "valid_from": "",
      "valid_to": ""
    }
  }

  // ChangeValidFrom(){
  //   console.log("Changedd")
  //   this.todayAValidFrom = this.couponData.valid_from//new Date(this.couponData.valid_from).toLocaleDateString('en-CA'); 
  //   console.log(this.todayAValidFrom)
  // }


}
