import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss']
})
export class AddVideoComponent implements OnInit {

  constructor(private router: Router, private toastr: ToastrService, private service: MyServicesService) { }

  ngOnInit() {
  }
  videoData = {
    "title": "",
    "url": "",
    "description": "",
    "role_id": 0
  }
  saveLoading = false;

  addvideoData() {

    if (this.videoData.title == '') {
      this.toastr.warning("Title is required!")
      return;
    }
    if (this.videoData.url == '') {
      this.toastr.warning("URL is required!")
      return;
    }
    if (this.videoData.description == '') {
      this.toastr.warning("Description is required!")
      return;
    }
    if (this.videoData.role_id == 0) {
      this.toastr.warning("Role is required!")
      return;
    }

    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/add-urls', this.videoData).subscribe(
      data => {
        //console.log(data);
        this.saveLoading = false
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.message);
          this.saveLoading = false
          // this.goBack();
          this.router.navigate([`../videoList`]);
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }
        this.saveLoading = false
        this.toastr.error("Something went wrong!")
      });
  }


  ClickCancel() {
    this.videoData = {
      "title": "",
      "url": "",
      "description": "",
      "role_id": 0
    }
  }

}
