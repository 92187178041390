import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: any = '';
  password: any = ''

  constructor(private router: Router, private service: MyServicesService, private toastr: ToastrService) { }

  ngOnInit() {
  }


  SignIn() {

    if (this.email == '') {
      this.toastr.warning("Email is required!")
      return
    }
    if(!(/^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})$/.test(this.email))){
      this.toastr.warning("The email must be a valid email address.")
      return
      
    } 
    if (this.password == '') {
      this.toastr.warning("Password is required!")
      return
    }
    var data = {
      "email": this.email,
      "password": this.password
    }

    console.log(data)
    this.service.postMethod(this.service.adminAPI + 'api/admin/sign-in', data).subscribe(
      res => {
        console.log(res);

        var data = JSON.stringify(res)

        var p = JSON.parse(data);
        console.log(p.data)
        if (p.data.code == 403) {

          this.toastr.error(p.message)
          return;
        }
        if (p.data.code == 401) {

          this.toastr.error(p.message)
          return
        }
        if (p.data.code == 400) {

          this.toastr.error(p.message)
          return;
        }

        if (p.data.code == 404) {

          this.toastr.error(p.message)
          return;
        }


        if (p.data.code == 200) {

          window.localStorage.setItem("Token", p.data.token)
          window.localStorage.setItem("LoggedInUserName", p.data.name)
          // this.toastr.success("Sign in Successful!");
          this.router.navigate([`../dashboard`]);


        }


      },
      err => {
        console.log(err)
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }
        this.toastr.error("Something went wrong!")
      });
  }
}
