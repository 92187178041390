import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layout/admin/title/title.component';
import { AuthComponent } from './layout/auth/auth.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './CCNPages/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { UserListComponent } from './pages/CCNP/user-list/user-list.component';
import { CouponListComponent } from './pages/CCNP/coupon-list/coupon-list.component';
import { PlanComponent } from './pages/CCNP/plan/plan.component';
import { PaymentListComponent } from './pages/CCNP/payment-list/payment-list.component';
import { ForgotPasswordComponent } from './CCNPages/forgot-password/forgot-password.component';
import { AuthServiceService } from './CCNServices/Service/auth-service.service';
import { DataTablesModule } from 'angular-datatables';
import { SubscriptionListComponent } from './pages/CCNP/subscription-list/subscription-list.component';
import { AddCouponComponent } from './pages/CCNP/coupon-list/add-coupon/add-coupon.component';
import { EditCouponComponent } from './pages/CCNP/coupon-list/edit-coupon/edit-coupon.component';
import { ViewCouponComponent } from './pages/CCNP/coupon-list/view-coupon/view-coupon.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AssignCouponPopupComponent } from './pages/CCNP/assign-coupon-popup/assign-coupon-popup.component';
import { UserProfileComponent } from './pages/CCNP/user-profile/user-profile.component';
import { CreateAdminComponent } from './pages/CCNP/create-admin/create-admin.component';
import { ReviewPageComponent } from './pages/CCNP/review-page/review-page.component';
import { AdminUserListComponent } from './pages/CCNP/admin-user-list/admin-user-list.component';
import { MatButtonModule } from '@angular/material/button';
import { RefundPopupComponent } from './pages/CCNP/refund-popup/refund-popup.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { VideoListComponent } from './pages/CCNP/video-list/video-list.component';
import { AddVideoComponent } from './pages/CCNP/video-list/add-video/add-video.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ViewVideoComponent } from './pages/CCNP/video-list/view-video/view-video.component';
import { EditVideoComponent } from './pages/CCNP/video-list/edit-video/edit-video.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SendMailPopupComponent } from './pages/CCNP/send-mail-popup/send-mail-popup.component';
import { UpdateSequenceVideoPopupComponent } from './pages/CCNP/update-sequence-video-popup/update-sequence-video-popup.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CheckUserComponent } from './CCNPages/check-user/check-user.component';
import { MatCheckboxModule, MatRadioModule } from '@angular/material';



@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BreadcrumbsComponent,
    TitleComponent,
    AuthComponent,
    LoginComponent,
    UserListComponent,
    CouponListComponent,
    PlanComponent,
    PaymentListComponent,
    ForgotPasswordComponent,
    SubscriptionListComponent,
    AddCouponComponent,
    EditCouponComponent,
    ViewCouponComponent,
    AssignCouponPopupComponent,
    UserProfileComponent,
    CreateAdminComponent,
    ReviewPageComponent,
    AdminUserListComponent,
    RefundPopupComponent,
    VideoListComponent,
    AddVideoComponent,
    ViewVideoComponent,
    EditVideoComponent,
    SendMailPopupComponent,
    UpdateSequenceVideoPopupComponent,
    CheckUserComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot(),
    DataTablesModule,
    MatDialogModule,
    MatButtonModule,
    NgxPaginationModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    DragDropModule,
    RichTextEditorAllModule,
    NgImageFullscreenViewModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule
    // MatButtonToggleModule,
    //     MatDatepickerModule,
    //     MatInputModule,
    //     MatFormFieldModule,
    //     MatNativeDateModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthServiceService, multi: true }
  ],
  entryComponents: [AssignCouponPopupComponent, RefundPopupComponent, SendMailPopupComponent, UpdateSequenceVideoPopupComponent
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
