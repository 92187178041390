import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AssignCouponPopupComponent } from '../assign-coupon-popup/assign-coupon-popup.component';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-refund-popup',
  templateUrl: './refund-popup.component.html',
  styleUrls: ['./refund-popup.component.scss']
})
export class RefundPopupComponent implements OnInit {

  amount
  refundLink =""

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RefundPopupComponent>, private service: MyServicesService, public dialog: MatDialog, private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.data)
    console.log(this.data.paymentData.user_name)
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


  clickApply() {

   
     
        var result =JSON.parse(this.data.paymentData.response);
        console.log(result.resource);
        if(result.resource == undefined || result.resource == "undefined"){
          this.toastr.warning("Invalid data.")
      return;
        }
        for(var j=0;j<result.resource.links.length;j++){
          if(result.resource.links[j].method == "POST" && result.resource.links[j].rel == "refund"){
            console.log(result.resource.links[j].href)
            this.refundLink = result.resource.links[j].href;
          }
        }
       
      
      
    
    

    if (this.data.paymentData.transaction_id == 0) {
      this.toastr.warning("Transaction id not found")
      return;
    }
    if (this.amount == undefined || this.amount == "" || this.amount == null) {
      this.toastr.warning("Amount can't be empty")
      return;
    }
    console.log(this.amount);
    console.log(this.data.paymentData.paid_amount)
    if (this.amount > this.data.paymentData.paid_amount) {
      this.toastr.warning("The refund amount must not be greater than the paid amount.")
      return;
    }
    this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to Refund the amount"
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        console.log(("Refund Component Yesss"))
        this.applyRefund()
      }
      else {
        console.log(("Refund Component elseeeees"))
        this.closeDialog()
      }
    });
  }


  applyRefund() {
    var data = {
      "user_id": this.data.paymentData.user_id,
      "payment_id": this.data.paymentData.payment_id,
      "setRefTransId": this.data.paymentData.transaction_id,
      "setAmount": this.amount,
      "refund_url": this.refundLink
    }

  
    console.log(data)


    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/refund-paypal-payment', data).subscribe(
      data => {
        //console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {

          this.toastr.success(resultCode.data.message);
          this.dialogRef.close(true);
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 401) {
          if (p.error.data.message == "error") {
            this.toastr.error(p.error.data.display_message)
          }
          else {
            this.toastr.error(p.error.data.message)
          }
          this.closeDialog()
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }

        this.toastr.error("Something went wrong!")
        this.closeDialog()
      });

  }
}
