import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyServicesService } from '../../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {
  id
  videoData = {
    "title": "",
    "url": "",
    "description": "",
    "role_id": 0
  }

  constructor(private service: MyServicesService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.id = params['id'];

    });
    this.getVideoData()
  }

  ngOnInit() {
  }

  getVideoData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/list-urls', { id: this.id }).subscribe(
      data => {
        console.log(data)
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result);
        this.videoData.title = resultCode.data.data[0].title
        this.videoData.url = resultCode.data.data[0].urls
        this.videoData.description = resultCode.data.data[0].description
        this.videoData.role_id = resultCode.data.data[0].roles_id

      },
      err => {
        //console.log(err)
      });
  }



}
