import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../CCNServices/Service/my-services.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  email: any = '';
  constructor(private router: Router, private service: MyServicesService, private toastr: ToastrService) { }

  ngOnInit() {
  }


  onSubmit() {
    if (this.email == '') {
      this.toastr.warning("Email is required!")
      return
    }

    if (!(/^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})$/.test(this.email))) {
      this.toastr.warning("The email must be a valid email address.")
      return

    }

    var data = {
      "email": this.email,
    }

    this.service.postMethod(this.service.adminAPI + 'api/admin/forgot-password', data).subscribe(
      res => {
        console.log(res);

        var data = JSON.stringify(res)

        var p = JSON.parse(data);
        console.log(p.data)
        if (p.data.code == 403) {

          this.toastr.error(p.data.message)
          return;
        }
        if (p.data.code == 401) {

          this.toastr.error(p.data.message)
          return
        }
        if (p.data.code == 400) {

          this.toastr.error(p.data.message)
          return;
        }

        if (p.data.code == 404) {

          this.toastr.error(p.data.message)
          return;
        }


        if (p.data.code == 200) {
          this.toastr.success(p.data.message)
          return;
          // window.localStorage.setItem("Token", p.data[0].user_token)
          // this.toastr.success("Sign in Successful!");
          // this.router.navigate([`../login`]);


        }


      },
      err => {
        console.log(err)
        console.log(err);

        var data = JSON.stringify(err)
        var roles_data = []
        var p = JSON.parse(data);
        console.log(p)
        //  alert(p.error.data.message)
        if (p.error.data.code == 403) {

          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {

          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {

          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {

          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 422) {

          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {

          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {

          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {

          this.toastr.error(p.error.data.message)
          return;
        }
        this.toastr.error("Something went wrong!")
      });

  }
}
