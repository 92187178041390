import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './CCNPages/forgot-password/forgot-password.component';
import { LoginComponent } from './CCNPages/login/login.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AdminUserListComponent } from './pages/CCNP/admin-user-list/admin-user-list.component';
import { AddCouponComponent } from './pages/CCNP/coupon-list/add-coupon/add-coupon.component';
import { CouponListComponent } from './pages/CCNP/coupon-list/coupon-list.component';
import { EditCouponComponent } from './pages/CCNP/coupon-list/edit-coupon/edit-coupon.component';
import { ViewCouponComponent } from './pages/CCNP/coupon-list/view-coupon/view-coupon.component';
import { CreateAdminComponent } from './pages/CCNP/create-admin/create-admin.component';
import { PaymentListComponent } from './pages/CCNP/payment-list/payment-list.component';
import { PlanComponent } from './pages/CCNP/plan/plan.component';
import { ReviewPageComponent } from './pages/CCNP/review-page/review-page.component';
import { SubscriptionListComponent } from './pages/CCNP/subscription-list/subscription-list.component';
import { UserListComponent } from './pages/CCNP/user-list/user-list.component';
import { UserProfileComponent } from './pages/CCNP/user-profile/user-profile.component';
import { AddVideoComponent } from './pages/CCNP/video-list/add-video/add-video.component';
import { EditVideoComponent } from './pages/CCNP/video-list/edit-video/edit-video.component';
import { VideoListComponent } from './pages/CCNP/video-list/video-list.component';
import { ViewVideoComponent } from './pages/CCNP/video-list/view-video/view-video.component';
import { CheckUserComponent } from './CCNPages/check-user/check-user.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'checkUser',
    component: CheckUserComponent,
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminComponent,
    children: [

      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule)
      },
      {
        path: 'userList',
        component: UserListComponent,
        data: {
          breadcrumb: 'User Management',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }
      },
      {
        path: 'couponList',
        component: CouponListComponent,
        data: {
          breadcrumb: 'Coupon Management',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }
      },

      {
        path: 'paymentList',
        component: PaymentListComponent,
        data: {
          breadcrumb: 'Payment Management',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }
      },
      {
        path: 'subscriptionList',
        component: SubscriptionListComponent,
        data: {
          breadcrumb: 'Subscription Management',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }
      },
      {
        path: 'videoList',
        component: VideoListComponent,
        data: {
          breadcrumb: 'Video Management',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }
      },
      {
        path: 'plan',
        component: PlanComponent,
      },
      {
        path: 'addCoupon',
        component: AddCouponComponent,
      },
      {
        path: 'addVideo',
        component: AddVideoComponent,
      },
      {
        path: 'userProfile/:id',
        component: UserProfileComponent,
      },

      {
        path: 'editCoupon/:id',
        component: EditCouponComponent,
      },
      {
        path: 'viewCoupon/:id',
        component: ViewCouponComponent,
      },

      {
        path: 'editVideo/:id',
        component: EditVideoComponent,
      },
      {
        path: 'viewVideo/:id',
        component: ViewVideoComponent,
      },

      {
        path: 'createAdmin',
        component: CreateAdminComponent,
      },
      {
        path: 'reviews',
        component: ReviewPageComponent,
        data: {
          breadcrumb: 'Reviews',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }
      },
      {
        path: 'adminUserList',
        component: AdminUserListComponent,
        data: {
          breadcrumb: 'Admin User List',
          icon: 'icofont icofont-file-document bg-c-pink',
          breadcrumb_caption: '',
          status: true
        }

      },
      // {
      //   path: 'notifications',
      //   loadChildren: () => import('./pages/ui-elements/advance/notifications/notifications.module').then(m => m.NotificationsModule)
      // },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/profile/profile.module').then(m => m.ProfileModule)
      },
    ]
  },





];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
