import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';
import { RefundPopupComponent } from '../refund-popup/refund-popup.component';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {

  couponList: any
  loading = true;
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private service: MyServicesService, public dialog: MatDialog) {
    
    this.getPaymentData()
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };


    // setTimeout(function () {
    //   $(function () {
    //     $('#paymentTable').DataTable().destroy()
    //     $('#paymentTable').DataTable(
    //       {

    //       //   lengthMenu: [
    //       //     [5,10, 25, 50, -1],
    //       //     [5,10, 25, 50, 'All'],
    //       // ],
    //       }
    //     );
    //   });
    // }, 1500);
  }




  page = 1;
  search = ''
  gateWay =''
  limit = 10;
  pageSize = 10;
  total = 10
  pageSizes = [10, 25, 50, 100]


  ngOnInit() {

  }

  radioChange(event: any) {
  // this.gateWay = event.value
    this.getPaymentData();
  }

  getPaymentData() {
    var data = {
      "skip": (this.page - 1) * this.limit,
      "limit": this.limit,
      "search": this.search,
      "gateway" :this.gateWay

    }
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-payment-records', data).subscribe(
      data => {
        console.log(data)
        this.couponList = data
        this.loading = false
        this.total = this.couponList.data.transaction_count
        // $('#paymentTable').DataTable().destroy();
        // $(function () {

        //   $('#paymentTable').DataTable(
        //     {
        //       retrieve: true,
        //       destroy: true,
        //     }
        //   );

        // });
        

      },
      err => {
        //console.log(err)
      });
  }


  handlePageChange(event: any) {
    this.page = event;
    this.getPaymentData()
    this.loading = true
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.limit = parseInt(event.target.value);
    //console.log(this.limit)
    this.page = 1;
    this.getPaymentData()
    this.loading = true
  }

  searchTitle(): void {
    this.page = 1;
    this.getPaymentData()
    this.loading = true
  }


  openDialog(reviewData): void {
    this.dialog.open(RefundPopupComponent, {
      width: '450px',
      data: {
        paymentData: reviewData
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        console.log(("Payment List  Component Yesss " + res))
        // this.getPaymentData()
      }
      else {
        console.log(("Payment list Component elseeeee"))
      }
    });
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dialog.closeAll()
  }

  checkRefundButtonEnable(data: any) {
    if (data.payment_status == 1 && !(data.paid_amount == 0.00) && (!(new Date().getTime() > new Date(data.expiry_date).getTime()))) {
      return true
    }
    else {
      return false
    }
  }


}
