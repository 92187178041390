import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss']
})
export class EditVideoComponent implements OnInit {

  id
  videoData = {
    "id": '',
    "title": "",
    "url": "",
    "description": "",
    "role_id": 0
  }
  role_Id = '0'

  constructor(private router: Router, private service: MyServicesService, private route: ActivatedRoute, private toastr: ToastrService) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.videoData.id = this.id
    });
    this.getVideoData()
  }

  ngOnInit() {
  }

  getVideoData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/list-urls', { id: this.id }).subscribe(
      data => {
        console.log(data)
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result);
        this.videoData.title = resultCode.data.data[0].title
        this.videoData.url = resultCode.data.data[0].urls
        this.videoData.description = resultCode.data.data[0].description
        this.role_Id = (resultCode.data.data[0].roles_id).toString()

      },
      err => {
        //console.log(err)
      });
  }


  editVideoData() {
    if (this.videoData.title == '') {
      this.toastr.warning("Title is required!")
      return;
    }
    if (this.videoData.url == '') {
      this.toastr.warning("URL is required!")
      return;
    }
    if (this.videoData.description == '') {
      this.toastr.warning("Description is required!")
      return;
    }
    if (this.role_Id == '3') {
      this.videoData.role_id = 3
    }
    else {
      this.videoData.role_id = 4
    }

    console.log(this.videoData);
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/update-url', this.videoData).subscribe(
      data => {
        //console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.message);

          this.router.navigate([`../videoList`]);
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }

        this.toastr.error("Something went wrong!")
      });
  }

  ClickCancel() {

    this.getVideoData()
  }

}
