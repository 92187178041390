import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';
import { SendMailPopupComponent } from '../send-mail-popup/send-mail-popup.component';
import { AssignCouponPopupComponent } from '../assign-coupon-popup/assign-coupon-popup.component';
import { Router } from '@angular/router';



@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {


  userList: any
  loading = true;
  // @ViewChild(DataTableDirective,)
  // dtElement!: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();

  constructor(private toastr: ToastrService, private service: MyServicesService, public dialog: MatDialog, private router: Router) {
    //    this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 5,
    //   processing: true
    // };


    // setTimeout(function () {
    //   $(function () {
    //     $('#user-table').DataTable().destroy()
    //     $('#user-table').DataTable(
    //       {
    //       }
    //     );
    //   });
    // }, 2000);
  }

  ngOnInit() {
    this.getUserData();
    this.getUserDataSpecialist();
  }

  page = 1;
  search = ''
  limit = 10;
  pageSize = 10;
  total = 10
  pageSizes = [10, 25, 50, 100]
  getUserData() {
    console.log(this.page);
    var data = {
      "skip": (this.page - 1) * this.limit,
      "limit": this.limit,
      "search": this.search
    }

    console.log("Data " + JSON.stringify(data));
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-clients', data).subscribe(
      data => {
        console.log(data)
        this.userList = data
        console.log(this.userList.data.users_count);
        this.loading = false
        this.total = this.userList.data.users_count //- (this.userList.data.users_count % this.limit)

        console.log(this.total);
      },
      err => {
        //console.log(err)
      });
  }

  handlePageChange(event: any) {
    this.page = event;
    this.getUserData()
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.limit = parseInt(event.target.value);
    //console.log(this.limit)
    this.page = 1;
    this.getUserData()
  }

  searchTitle(): void {
    this.page = 1;
    this.getUserData()
  }


  openDialog(reviewData): void {
    this.dialog.open(SendMailPopupComponent, {
      width: '850px',
      data: {
        userData: reviewData
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {

      }
      else {

      }
    });
  }

  userListSpecialist: any
  pageSpecialist = 1;
  searchSpecialist = ''
  limitSpecialist = 10;
  pageSizeSpecialist = 10;
  totalSpecialist = 10
  pageSizesSpecialist = [10, 25, 50, 100]
  getUserDataSpecialist() {
    console.log(this.page);
    var data = {
      "skip": (this.pageSpecialist - 1) * this.limitSpecialist,
      "limit": this.limitSpecialist,
      "search": this.searchSpecialist
    }

    console.log("Data " + JSON.stringify(data));
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-specialists', data).subscribe(
      data => {
        console.log(data)
        this.userListSpecialist = data
        this.loading = false
        this.totalSpecialist = this.userListSpecialist.data.users_count //- (this.userList.data.users_count % this.limit)
      },
      err => {
        //console.log(err)
      });
  }

  handlePageChangeSpecialist(event: any) {
    this.pageSpecialist = event;
    this.getUserDataSpecialist()
  }

  handlePageSizeChangeSpecialist(event: any): void {
    this.pageSizeSpecialist = event.target.value;
    this.limitSpecialist = parseInt(event.target.value);
    //console.log(this.limit)
    this.pageSpecialist = 1;
    this.getUserDataSpecialist()
  }

  searchTitleSpecialist(): void {
    this.pageSpecialist = 1;
    this.getUserDataSpecialist()
  }


  ActiveDeactive(data) {
    const dialogRef = this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to change the user status"
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        console.log(("Refund Component Yesss"))
        this.callStatusChangeApi(data)
      }
      else {
        console.log(("Refund Component elseeeees"))
        data.roles_id == 3 ? this.getUserData() : this.getUserDataSpecialist();
      }
    });
  }

  callStatusChangeApi(data) {
    var request = {
      "id": data.id,
      "user_status": data.user_status == 9 ? 1 : 9,
      "roles_id": data.roles_id
    }
    console.log(request)
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/toggle-user-status', request).subscribe(
      data => {
        //console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          request.roles_id == 3 ? this.getUserData() : this.getUserDataSpecialist();
          this.toastr.success(resultCode.data.message);

        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          request.roles_id == 3 ? this.getUserData() : this.getUserDataSpecialist();
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }

        this.toastr.error("Something went wrong!")
      });
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

}
