import { Component, OnInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements OnInit {


  couponList :any
  loading =  true ;
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  constructor(private service: MyServicesService) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };


    // setTimeout(function () {
    //   $(function () {
    //     $('#user-table').DataTable().destroy()
    //     $('#user-table').DataTable(
    //       {

    //       //   lengthMenu: [
    //       //     [5,10, 25, 50, -1],
    //       //     [5,10, 25, 50, 'All'],
    //       // ],
    //       }
    //     );
    //   });
    // }, 1500);
   }

  ngOnInit() {
    this.getCouponData()
  }

  getCouponData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/get-coupons', {}).subscribe(
      data => {
        console.log(data)
        this.couponList = data
        this.loading = false
        $(function () {
          $('#user-table').DataTable().destroy()
          $('#user-table').DataTable(
            {
            }
          );
        });
      },
      err => {
        //console.log(err)
        if(err.error.data.code == 404){
          this.couponList ={
            "data": {
                "data": []
              }
            }
        }
        this.loading = false
      });
  }

}
