import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AssignCouponPopupComponent } from '../assign-coupon-popup/assign-coupon-popup.component';
import { MyServicesService } from '../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-send-mail-popup',
  templateUrl: './send-mail-popup.component.html',
  styleUrls: ['./send-mail-popup.component.scss']
})
export class SendMailPopupComponent implements OnInit {

  content = ""; subject;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendMailPopupComponent>, private service: MyServicesService, public dialog: MatDialog, private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.data)
    console.log(this.data.userData.user_name)
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


  clickSend() {
    if (this.subject == undefined || this.subject == "" || this.subject == null) {
      this.toastr.warning("Subject can't be empty")
      return;
    }

    if (this.content == undefined || this.content == "" || this.content == null) {
      this.toastr.warning("Content can't be empty")
      return;
    }
    this.dialog.open(AssignCouponPopupComponent, {
      width: '450px',
      data: {
        message: "You want to Send the mail"
      }
    }).afterClosed().subscribe(res => {
      //console.log(res)
      if (res) {
        console.log(("Refund Component Yesss"))
        this.sendMailApi()
      }
      else {
        console.log(("Refund Component elseeeees"))
        this.closeDialog()
      }
    });
  }


  sendMailApi() {
    var data =
    {
      "user_name": this.data.userData.user_name,
      "mail_id": this.data.userData.email,
      "subject": this.subject,
      "mail_content": this.content
    }
    console.log(data)


    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/send-mail-specialist', data).subscribe(
      data => {
        //console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {

          this.toastr.success(resultCode.data.message);
          this.dialogRef.close(true);
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 401) {
          if (p.error.data.message == "error") {
            this.toastr.error(p.error.data.response.errorText)
          }
          else {
            this.toastr.error(p.error.data.message)
          }
          this.closeDialog()
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          this.closeDialog()
          return;
        }

        this.toastr.error("Something went wrong!")
        this.closeDialog()
      });

  }

  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode']
  };
}
