import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MyServicesService } from '../../../../CCNServices/Service/my-services.service';

@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.scss']
})
export class EditCouponComponent implements OnInit {

  id
  todayAValidFrom = new Date().toLocaleDateString('en-CA');

  couponData = {
    'id': '',
    "name": "",
    "description": "",
    "coupon_code": "",
    "discount": "",
    "discount_type": 1,
    "valid_from": "",
    "valid_to": "",
    "status": 0
  }

  constructor(private router: Router, private toastr: ToastrService, private service: MyServicesService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.id = params['id'];

    });
    this.getCouponData()
  }

  ngOnInit() {
  }

  getCouponData() {
    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/view-coupon', { id: this.id }).subscribe(
      data => {
        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result);
        this.couponData.name = resultCode.data.couponData.coupon_name
        this.couponData.coupon_code = resultCode.data.couponData.coupon_code
        this.couponData.description = resultCode.data.couponData.coupon_description
        this.couponData.discount = resultCode.data.couponData.coupon_discount
        this.couponData.valid_from = resultCode.data.couponData.coupon_valid_from
        this.couponData.valid_to = resultCode.data.couponData.coupon_valid_to
        this.couponData.id = this.id
        this.couponData.status = resultCode.data.couponData.coupon_status
      },
      err => {
        //console.log(err)
      });
  }

  ClickCancel() {
    this.getCouponData();
  }

  editCouponData() {

    if (this.couponData.name == '') {
      this.toastr.warning("Name is required!")
      return;
    }
    if (this.couponData.coupon_code == '') {
      this.toastr.warning("Coupon code is required!")
      return;
    }
    if (this.couponData.description == '') {
      this.toastr.warning("Description is required!")
      return;
    }
    if (this.couponData.discount == '') {
      this.toastr.warning("Discount is required!")
      return;
    }
    if (this.couponData.valid_from == '') {
      this.toastr.warning("Valid From is required!")
      return;
    }
    if (this.couponData.valid_to == '') {
      this.toastr.warning("Valid to is required!")
      return;
    }
    if ((parseInt(this.couponData.discount, 10)) > 100) {
      this.toastr.warning("Discount must be a Percentage")
      return;
    }
    console.log(new Date(this.couponData.valid_from).getTime() > new Date(this.couponData.valid_to).getTime())
    if (new Date(this.couponData.valid_from).getTime() > new Date(this.couponData.valid_to).getTime()) {
      this.toastr.warning("The valid from must be a date before or equal to valid to.")
      return;
    }
    if (this.couponData.status) {
      this.couponData.status = 1
    } else {
      this.couponData.status = 0
    }


    this.service.postMethodGetData(this.service.adminAPI + 'api/admin/edit-coupon', this.couponData).subscribe(
      data => {
        console.log(data);

        var result = JSON.stringify(data)
        var resultCode = JSON.parse(result)
        if (resultCode.data.code == 200) {
          this.toastr.success(resultCode.data.message);
          this.router.navigate([`../couponList`]);
        }
        if (resultCode.data.code == 403) {
          this.toastr.error(resultCode.data.message)
          return;
        }
        if (resultCode.data.code == 401) {
          this.toastr.error(resultCode.data.message)
          return
        }
        if (resultCode.data.code == 400) {
          this.toastr.error(resultCode.data.message)
          return;
        }

      },
      err => {
        var data = JSON.stringify(err)
        var p = JSON.parse(data);
        console.log(p)
        if (p.error.data.code == 403) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 401) {
          this.toastr.error(p.error.data.message)
          return
        }
        if (p.error.data.code == 400) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }

        if (p.error.data.code == 422) {
          this.toastr.error(p.error.errors.message)
          return;
        }
        if (p.error.data.code == 404) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 500) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 501) {
          this.toastr.error(p.error.data.message)
          return;
        }
        if (p.error.data.code == 502) {
          this.toastr.error(p.error.data.message)
          return;
        }
        this.toastr.error("Something went wrong!")
      });
  }


}
